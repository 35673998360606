@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
:root {
  --primary-color: #185ee0;
  --secondary-color: #e6eef9;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  background-color: rgba(230, 238, 249, 0.5);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  /* background: rgb(52, 23, 45); */
  /* background: linear-gradient(
    0deg,
    rgba(52, 23, 45, 1) 0%,
    rgba(23, 14, 59, 1) 100%
  ); */
  background: linear-gradient(-45deg, #295270, #524175, #295270, #524175);
  background-size: 400% 400%;
  animation: gradient 12s ease infinite;
  color: #fff;
  overflow: hidden;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.mobile-only {
  display: none;
}

.desktop-only {
  display: flex;
}

.hidden {
  display: none;
}

button {
  outline: 0 !important;
}

textarea {
  outline: 0 !important;
}

textarea:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 !important;
  border: 1px solid rgba(255, 255, 255, 0.7) !important;
}

textarea::placeholder,
input::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}

body.censored {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 12s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.landing-card {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  min-width: 320px;
  width: 30%;
  max-width: none;
  color: #fff;
  background-color: #1a1d25;
}

.landing-card .disclosure {
  font-size: 12px;
  opacity: 0.5;
  font-weight: 700;
}

.landing-card h1 {
  font-weight: 800;
  letter-spacing: -5px;
  text-transform: lowercase;
}

.landing-card .landing-card-links {
  position: absolute;
  top: -30px;
  font-weight: 600;
  width: 100%;
  left: 50%;
  transform: translatex(-50%);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.landing-card .landing-card-links span {
  opacity: 0.4;
}

.landing-card .landing-card-links a {
  color: #fff;
  opacity: 0.4;
  font-size: 12px;
}

.landing-card .landing-card-links a:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.tos-card.card {
  color: #fff;
  background-color: #1a1d25;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 600px;
}

.tos-card h6 {
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}

.tos-card h5 {
  text-align: center;
  font-weight: 700;
}

.tos-card p {
  font-weight: 500;
  font-size: 12px;
}

.tos-card picture {
  margin: 10px auto;
}

.room-list-card.card {
  color: #fff;
  background-color: #1a1d25;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  overflow-y: scroll;
  padding-top: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 600px;
}

.room-list-card .content {
  overflow-y: scroll;
}

.room-list-card h4.no-dares {
  text-align: center;
  font-weight: 800;
  opacity: 0.3;
  margin: 100px 0 0 0;
}

.room-list-card .no-dares-link {
  text-align: center;
  color: #fff;
  margin-bottom: 100px;
  opacity: 0.6;
}

.room-list-card .no-dares-link:hover {
  opacity: 0.9;
}

.room-list-card .navigate-back:hover,
.tos-card .navigate-back:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.room-list-card .navigate-back,
.tos-card .navigate-back {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  padding: 5px;
}

.room-list-card picture {
  margin: 25px auto 10px;
}

.room-list-card h5 {
  font-weight: 800;
  text-align: center;
  margin-bottom: 50px;
}

.room-list-card thead {
  font-size: 12px;
}

.room-list-card .table thead th {
  border: 0px;
  text-align: center;
}

.room-list-card .table tbody td {
  border: 0px;
  text-align: center;
}

.room-list-card .table tbody td .dare-this-room {
  color: #fff;
  font-weight: 800;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
}

.room-list-card .table tbody td .dare-this-room:hover {
  text-decoration: underline;
}

.room-list-card .table tbody td .dare-this-room svg {
  margin-left: 10px;
}

.room-list-card .table .adnotations {
  font-size: 12px;
  font-weight: 500;
}

.room-list-card .not-on-mobile {
  display: block;
}

.room-list-card span.not-on-mobile {
  display: inline;
}

.room-list-card .table {
  overflow-y: scroll;
}

.room-list-card .table thead {
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
  background-color: #1a1d25;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

button:hover {
  background-color: #2980b9;
}

input {
  padding: 10px;
  font-size: 16px;
}

/* New styles for the chat page */
.chat-container {
  max-width: 320px;
  max-height: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.chat-messages {
  overflow-y: auto;
  flex: 1;
}

.chatContainer .inactivity {
  width: 100%;
  height: 100%;
}

.chatContainer .inactivity span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  text-align: center;
  width: 80%;
}

.chat-input {
  display: flex;
  margin-top: 10px;
}

.chat-input input {
  flex: 1;
}

.chat-input button {
  margin-left: 10px;
}

.chat-message {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.4);
  color: #fff;
  border-radius: 10px;
}

.chat-message.current {
  background-color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.7);
}

.chat-room-tools,
.content-wrapper--tools {
  height: 40px;
  font-size: 12px;
  line-height: 40px;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  background: #1a1d25;
  border-radius: 8px;
}

.content-wrapper--tools {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999999;
  padding: 0 5px;
}

.chat-room-tools.mobile-only {
  margin-bottom: 60px;
}

.chat-room-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 74%;
  overflow: hidden;
}

.chat-room-tools .chat-room-link-copy {
  margin-left: 10px;
  font-size: 18px;
}
.chat-room-tools.mobile-only .chat-room-link-copy {
  margin-left: auto;
}

.chat-room-close,
.chat-room-link-copy,
.chat-room-pop-out,
.content-wrapper--tools--hide,
.content-wrapper--tools--pop-in {
  background: transparent;
  padding: 4px 8px;
  border-radius: 5px;
  margin: 0;
  font-size: 20px;
  line-height: normal;
  height: 31px;
  vertical-align: middle;
}

.content-wrapper--tools--hide,
.content-wrapper--tools--pop-in {
  margin-bottom: 2px;
}

.chat-room-close:hover,
.chat-room-link-copy:hover,
.content-wrapper--tools--hide:hover,
.content-wrapper--tools--pop-in:hover {
  background: rgba(255, 255, 255, 0.3);
}

.switch-container label {
  margin: 0;
}
.switch-container .container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.switch-container .tabs {
  display: flex;
  position: relative;
  background: linear-gradient(-45deg, #295270, #524175, #295270, #524175);
  background-size: 400% 400%;
  -webkit-animation: gradient 12s ease infinite;
  animation: gradient 12s ease infinite;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.5);
  padding: 8px;
  border-radius: 99px;
}
.switch-container .tabs * {
  z-index: 2;
}
.switch-container input[type="radio"] {
  display: none;
}
.switch-container .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  color: #fff;
}
.switch-container input[type="radio"]:checked + label {
  color: var(--primary-color);
}
.switch-container input[type="radio"]:checked + label > .notification {
  background-color: var(--primary-color);
  color: #fff;
}
.switch-container input[id="radio-1"]:checked ~ .glider {
  transform: translateX(0);
}
.switch-container input[id="radio-2"]:checked ~ .glider {
  transform: translateX(100%);
}
.switch-container input[id="radio-3"]:checked ~ .glider {
  transform: translateX(200%);
}
.switch-container .glider {
  position: absolute;
  display: flex;
  height: 40px;
  width: 100px;
  background-color: var(--secondary-color);
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}
@media (max-width: 700px) {
  .switch-container .tabs {
    transform: scale(0.6);
  }
}

.content-wrapper--replacement {
  position: absolute;
  font-size: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.cooldown-message {
  font-size: 12px;
  font-weight: 400;
  vertical-align: middle;
  line-height: 46px;
}

/* LandingPage.css */
.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.card {
  max-height: 560px;
  transition: transform 0.3s;
  border-radius: 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.card-title {
  text-align: center;
}

.input-group {
  margin-top: 1rem;
  text-align: center;
}

.btn-double-dare {
  margin-top: 1rem;
  transition: transform 0.3s;
}

.btn-double-dare:hover {
  transform: scale(1.05);
}

.generateButtons {
  max-width: 320px;
  position: absolute;
  transform: translateX(-50%);
  transition: 0.2s ease;
  z-index: 99999;
  background-color: #1a1d25;
  border-radius: 10px;
}

.generateButtons .trigger {
  font-weight: 800;
}

.generateButtons .minutes {
  padding-right: 10px;
}

.generateButtons input {
  height: 40px;
  border: 0;
}
.generateButtons button:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.generateButtons button:first-of-type {
  margin: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
/* ChatPage.css */

.container {
  position: relative;
}
.chat-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 400px;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white; /* Text color */
  overflow-y: auto; /* Enable vertical scrolling for chat messages */
  padding: 10px; /* Add some padding to the wrapper */
}

.chatContainer.experience-container .chat-wrapper {
  max-width: 50%;
}

.chat-wrapper.only-chat {
  height: 100vh !important; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) !important;
}

.chat-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  max-width: 400px;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
}

.chat-input input {
  flex: 1;
}

.chat-messages {
  overflow-y: auto;
  max-height: calc(
    100vh - 98px
  ); /* Adjust the max height based on your design */
  height: calc((var(--vh, 1vh) * 100) - 98px);
  margin-bottom: 60px; /* Add margin at the bottom */
  margin-top: 10px;
}

.chat-input {
  position: fixed;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: transparent;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-width: 400px;
}

.form-control {
  border: none;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  padding-right: 49px;
  resize: none;
}

.form-control:hover,
.form-control:focus {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
}

.btn-send {
  background-color: #4caf50;
  border: none;
  border-radius: 8px;
  right: 72px;
  top: 0;
  width: 35px;
  height: 35px;
  padding: 0;
  cursor: pointer;
  position: absolute;
  transition: background-color 0.3s;
}

.btn-send svg {
  color: #fff;
}

.btn-send:hover {
  background-color: #45a049;
}

.countdown {
  color: #fff;
  font-weight: 800;
  text-align: right;
  min-width: 50px;
}

.video-player,
.link-player {
  position: absolute;
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
.image-wrapper {
  position: absolute;
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.content-wrapper {
  position: absolute;
  width: calc(100% - 400px);
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.chatContainer.experience-container .content-wrapper {
  width: 50%;
}

.chatContainer.popped-out .content-wrapper {
  width: 100% !important;
}

.image-wrapper .contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
}

.image-wrapper .bg {
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
}

.image-wrapper .cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.instagram-media-rendered {
  position: absolute;
  top: 50%;
  left: calc((100% - 400px) / 2);
  transform: translate(-50%, -50%);
  min-width: 400px !important;
}

.backdrop {
  display: none;
}

#embed-video-container {
  background: transparent !important;
}

@media screen and (max-width: 1280px) {
  .chatContainer.experience-container .chat-wrapper {
    max-width: 50%;
  }
  .chatContainer.experience-container .chat-input {
    max-width: 50%;
  }

  .chat-wrapper {
    max-width: 320px;
  }

  .chat-input {
    max-width: 320px;
  }

  .content-wrapper {
    width: calc(100% - 320px);
  }

  .chatContainer.experience-container .content-wrapper {
    width: 50%;
    max-width: 50%;
  }

  .instagram-media-rendered {
    position: absolute;
    top: 50%;
    left: calc((100% - 320px) / 2);
    transform: translate(-50%, -50%);
    min-width: 400px !important;
  }
}

@media screen and (max-width: 960px) {
  .mobile-only {
    display: flex;
  }

  .desktop-only {
    display: none;
  }

  .chatContainer .instagram-link {
    width: 100%;
    height: 50vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 50);
    position: absolute;
  }

  .chatContainer .instagram-link a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    display: block;
  }

  .chat-wrapper {
    max-width: 100% !important;
    height: 50vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 50);
    bottom: 0;
    top: auto;
  }

  .chat-input {
    max-width: 100% !important;
    width: 100% !important;
  }

  .video-player,
  .image-wrapper,
  .link-player {
    height: 50vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 50);
  }

  .content-wrapper {
    width: 100% !important;
    max-width: 100% !important;
    height: 50vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 50);
  }

  .chat-messages {
    margin-bottom: 10px;
  }

  .instagram-media-rendered {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    z-index: -1;
    width: 100% !important;
  }

  .backdrop {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background: rgba(0, 0, 0, 0.75);
    z-index: -1;
  }

  .room-list-card,
  .tos-card {
    max-width: 90%;
  }
}

@media screen and (max-width: 690px) {
  .room-list-card .not-on-mobile,
  .room-list-card span.not-on-mobile {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .chatContainer .instagram-link {
    width: 100%;
    height: 30vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 30);
    position: absolute;
  }
  .chatContainer .instagram-link a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    display: block;
  }
  .chat-wrapper {
    max-width: 100%;
    height: 70vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 70);
    bottom: 0;
    top: auto;
  }

  .chat-input {
    max-width: 100%;
  }

  .video-player,
  .image-wrapper,
  .link-player {
    width: 100%;
    height: 30vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 30);
  }

  .content-wrapper {
    width: 100%;
    height: 30vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 30);
  }

  .chat-messages {
    margin-bottom: 10px;
  }

  .room-list-card .table .roomid {
    font-size: 12px;
  }
}

.missed-dare {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.missed-dare h3 {
  font-weight: 800;
}
.you-dared {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.you-dared h3 {
  font-weight: 800;
}
/* ChatPage.css */

.textanime p {
  color: #fff;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.1em; /* Adjust as needed */
  opacity: 0.4;
  font-weight: 800;
  font-size: 12px;
  top: 50%;
  position: absolute;
  max-width: 320px;
  animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.switch-container {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.switch-label {
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
  user-select: none;
  color: #999;
  transition: color 0.3s ease-in-out;
}

.switch-label.active {
  color: #fff;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsla(197, 100%, 63%, 1);

  background: linear-gradient(
    90deg,
    hsla(197, 100%, 63%, 1) 0%,
    hsla(294, 100%, 55%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(197, 100%, 63%, 1) 0%,
    hsla(294, 100%, 55%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(197, 100%, 63%, 1) 0%,
    hsla(294, 100%, 55%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#40C9FF", endColorstr="#E81CFF", GradientType=1 );
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.drop-zone {
  height: 200px;
  width: 100%;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  position: relative;
}

.drop-zone img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.drop-zone p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drop-zone .bg {
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
}

.drop-zone svg {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 99;
  opacity: 0.8;
  width: 50px;
}

/* MODAL */
/* Add this to your CSS file or use a styled-components approach */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.84);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: auto;
  max-width: 450px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff;
  background-color: #1a1d25;
  position: relative;
}

.modal-content button {
  background: transparent;
  position: absolute;
  top: -60px;
  left: 0;
}

.wt-experience-title {
  margin-bottom: 10px;
}

.experience-title {
  font-weight: 800;
}
